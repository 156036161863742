import { proseClasses } from "@website/components/Styles/prose"
import { routes } from "@website/routes"
import LinkWithArrow from "@website/components/LinkWithArrow/LinkWithArrow"

interface SendInfoLinkParagraphProps {
  text: string
  cta?: string
}

export default function SendInfoLinkParagraph({ text, cta = " Použijte tento formulář" }: SendInfoLinkParagraphProps) {
  return (
    <div className={`${proseClasses} max-w-none`}>
      <p>{text}</p>
      <LinkWithArrow arrow="right" to={routes.forRacers.getLink()}>
        {cta}
      </LinkWithArrow>
    </div>
  )
}
